$(function() {

	//выцепляем яблоки, вешаем класс
	if (navigator.userAgent.match(/(iPod|iPhone|iPad)/)) {
		$('body').addClass('ios')
	}

	//поиск в шапке
	$('header .search_button').click(function(){
		$("html, body").addClass("modal_open");
		document.body.style.paddingRight = '15px';
		$(".search_form").addClass('search_open');
		$('.search_field').focus();
	});

	$('header .search_close').click(function(){
		$(".search_form").removeClass('search_open');
		$("html, body").removeClass("modal_open");
		document.body.style.paddingRight = '0';
	});

	$(".sticky_search_button").on("click", function() {
		$(".search_menu").slideToggle(200);
	});

	//дропдаун лич.каба в шапке
	$(".user_dropdown").on("click", function() {
		$(".account_nav").slideToggle(200);
		$(".user_dropdown").toggleClass('user_dropdown_open');
	});

	//вызов галерей
	$('.gallery').lightGallery({
		selector: 'a'
	});

	//Lightgallery + Lightslider
	if ($(window).width() > 767) {
		$('.slider_gallery').lightSlider({
			gallery: true,
			item: 1,
			loop: true,
			vertical:true,
			slideMargin: 0,
			vThumbWidth: 50,
			thumbItem: 3,
			enableTouch: false,
			enableDrag: false,
			freeMove: false,
			responsive : [
				{
					breakpoint:767,
					settings: {
						thumbItem: 5
					}
				}
			]
		});
	};

	//горизонтальный слайдер на мобилках
	if ($(window).width() < 768) {
		$('.slider_gallery').lightSlider({
			gallery: true,
			item: 1,
			loop: true,
			slideMargin: 0,
			enableTouch: false,
			enableDrag: false,
			freeMove: false
		});
	};

	//плавный скроллинг до якоря
	var documentIsScrolled = false;
	$(".slide_to").on("click", function(e) {
		var scrollSize = $($(this).attr("href")).offset().top,
		diff = $(window).scrollTop() - scrollSize;

		if (!documentIsScrolled) {

			documentIsScrolled = true;
			if (diff > 20 || diff < -20) {

				$("body, html").animate({
					scrollTop: scrollSize
				}, 800, function() {
					documentIsScrolled = false;
				});
			} else {
				documentIsScrolled = false;
			}
		}

		e.preventDefault();
	});

	//карта
	var contactMap = $('#js-contacts-map');
	if (contactMap.length) {
		var myMap,
		data = contactMap[0].dataset;
		function mapInit() {
			var coords = [parseFloat(data.coordx) , parseFloat(data.coordy)];
			myMap = new ymaps.Map('js-contacts-map', {
				center: coords,
				zoom: parseInt(data.zoom),
				propagateEvents: true
			});
			myMap.behaviors.disable('scrollZoom');
			var placemark = new ymaps.Placemark(coords, {
					balloonContentBody: data.address
				},
				{
					// настройки метки
					iconLayout: 'default#image',
					iconImageHref: '/static/i/metka.png',
					// Размеры метки.
					iconImageSize: [41, 44],
					// Смещение левого верхнего угла иконки относительно
					// её "ножки" (точки привязки).
					iconImageOffset: [-13, -42]
				}
			);
			myMap.geoObjects.add(placemark);
		}
		ymaps.ready(mapInit);
	}

	//появление по скроллу
	$(window).scroll(function () {
		if ($(document).width() > 767) {
			if ($(this).scrollTop() > 500) {
				$('.small_header').addClass("sticky_small_header");
			} else {
				$('.small_header').removeClass("sticky_small_header");
			}
		}
	})

	//появление энчора to_top по скроллу
	var $toTop = $('.to_top');
	$(window).scroll(function () {
		if ($(window).scrollTop() > 1000) {
			$toTop.addClass('to_top--visible');
		} else {
			$toTop.removeClass('to_top--visible');
		}
	});

	//главный слайдер
	if ($('.swiper-container .swiper-slide').length === 1) {
		$('.swiper-container').addClass('swiper-disabled');
	}
	new Swiper('.main_slider', {
		pagination: '.swiper-pagination',
		nextButton: '.swiper-button-next',
		prevButton: '.swiper-button-prev',
		paginationClickable: true,
		spaceBetween: 0,
		slidesPerView: 1,
		loop: true,
		speed: 1500,
		autoplay: 5000,
		roundLengths: true,
		autoplayDisableOnInteraction: false,
		grabCursor: false
	});

	//мобильное меню
	$('.burger_btn').click(function() {
		$(this).toggleClass('close');
		if($(this).hasClass('close')) {
			$('.mobile_menu').addClass('open_menu');
			$("html, body").addClass("modal_open");
		}
		else {
			$('.mobile_menu').removeClass('open_menu');
			$("html, body").removeClass("modal_open");
		}
	});

	//кастомный селект
	$(".custom_select").select2({
		minimumResultsForSearch: Infinity
	});

	if ($(window).width() < 767) {
		$(".custom_select").select2('destroy');
	};

	//поля форм с подсказками
	if ($('.js_field').length) {
		var $inputs = $('input', '.js_field');
		var $textareas = $('textarea', '.js_field');

		var fn = function () {
			if ($(this).val().trim() !== '')
				$(this).closest('.form_group').addClass('form_group_filled');

			$(this).on('focus', function () {
				$(this).closest('.form_group').addClass('form_group_filled');
			});

			$(this).on('blur', function () {
				if ($(this).val().trim() === '')
					$(this).closest('.form_group').removeClass('form_group_filled');
			});
		};

		$inputs.each(fn);

		$textareas.each(fn);
	}

	//аккордион
	if ($(".js_accordion").length) {

		var $accordionBtn = $('.accordion_item_btn, .accordion_subitem_btn', '.js_accordion');
		$('.accordion_active').addClass('accordion_open');

		$accordionBtn.on('click', function (e) {
			var item = $(this).closest('.accordion_item, .accordion_subitem');

			item.toggleClass('accordion_open');
			e.preventDefault();
		});
	}

	//табы
	(function ($) { 
		$('.tabs_block ul.tabs').addClass('active').find('> li:eq(0)').addClass('current');
		
		$('.tabs_block ul.tabs li a').click(function (g) { 
			var tab = $(this).closest('.tabs_block'), 
				index = $(this).closest('li').index();
			
			tab.find('ul.tabs > li').removeClass('current');
			$(this).closest('li').addClass('current');
			
			tab.find('.tab_content').find('div.tabs_item').not('div.tabs_item:eq(' + index + ')').slideUp();
			tab.find('.tab_content').find('div.tabs_item:eq(' + index + ')').slideDown();
			
			g.preventDefault();
		} );
	})(jQuery);

	//плавающая корзина
	if ($('.js-info-flow').length) {
		var $info = $('.info');
		var $flow = $('.info__flow');
		var $flowHeight = $('.info__height');
		var $footer = $('.footer');

		function getScrollToFooter() {
			return $(document).height() - $(window).height() - $footer.outerHeight();
		}

		function setFlowHeight() {
			$flowHeight.height($flow.outerHeight());
		}

		function onScrollFlow(scrollToFooter) {
			if ($(window).scrollTop() > scrollToFooter) {
				$info.removeClass('info--flow');
			} else {
				$info.addClass('info--flow');
			}
		}
                
		$(window).load(function () {
			var scrollToFooter = getScrollToFooter();

			setFlowHeight();
			onScrollFlow(scrollToFooter);

			$(window).resize(function () {
				setFlowHeight();
				scrollToFooter = getScrollToFooter();
				onScrollFlow(scrollToFooter);
			});

			$(window).scroll(function () {
				onScrollFlow(scrollToFooter);
			});
		});

	}

	// Щас напишу для фильтров
	var $filterButtons = null;
	$('.js-open-advanced-filter').on('click', function() {
		$filterButtons = $(this).parent();

		$filterButtons.slideUp(500);
		$('.js-advanced-filter').slideDown(500);
	});

	$('.js-close-advanced-filter').on('click', function() {
		if ($filterButtons) {
			$filterButtons.slideDown(500);
		}

		$('.js-advanced-filter').slideUp(500);
	});
});